import React, { useState,useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addCustomerData, getAllCustomers, updateCustomerData } from '../../Redux/Actions/action.customers';
import { useNavigate } from 'react-router-dom';


function AddEdit({toast, editCustomer,closeModal,categoriesOptions,addCustomerData, updateCustomerData,getAllCustomers, pageInfo, modalTitle}) {
  const navigate = useNavigate();
  const [disableSave,setDisableSave] = useState(false);
  const [categoryOptionsArray, setCategoryOptionsArray] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchCategoriesInput,setSearchCategoriesInput] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const categoriesSelectRef = useRef(null);
  const [validationErrors,setValidationErrors] = useState({
    name:'',
    configuredCategories:''
  })

  const [customerDetails,setCustomerDetails] = useState({
    id:'',
    name:'',
    key:'uds'+ '-' + btoa(Date.now()).replace(/=/g,'') + '-' + Math.floor((Math.random() * 900000) + 100000),
    status:'Enable',
    configuredCategories:[]
  });

  useEffect(()=>{
    const modal = document.getElementById('modal-wrapper');
    modal.addEventListener('click',handleOutsideClick);
    return ()=>{
      modal.removeEventListener('click',handleOutsideClick);
    }
  },[]);

  useEffect(()=>{
    setCustomerDetails({
      id:editCustomer.id,
      name:editCustomer.name,
      key:editCustomer.key?editCustomer.key:'uds'+ '-' + btoa(Date.now()).replace(/=/g,'') + '-' + (Math.floor((Math.random() * 900000)+100000)),
      status:editCustomer.status,
      configuredCategories:editCustomer.configuredCategories
    });
    setSelectedCategories(editCustomer.categories);
    setValidationErrors({
      name:'',
      configuredCategories:''
    });
    setSearchCategoriesInput('');
    setDisableSave(false);
  },[editCustomer])

  useEffect(()=>{
    setCategoryOptionsArray(categoriesOptions);
  },[categoriesOptions]);

  const handleDropdown = () => {
    setShowOptions(prev=>!prev);
  }

  const handleCategoryOptionSelect = (category) => {
    if(selectedCategories && selectedCategories.find(el=>el == category)) return;
    setSelectedCategories(prev=>([...prev,category]));
    setCustomerDetails(prev=>({ ...prev, configuredCategories:[...prev.configuredCategories,category.id] }));
  }

  const handleSearchCategory = (searchterm) => {
    setCategoryOptionsArray(categoriesOptions.filter(el=> el.name.toLowerCase().includes(searchterm.toLowerCase())))
  }

  const removeSelectedCategory = (categoryId) => {
    setSelectedCategories(prev=>([...prev.filter(el=>el.id!==categoryId)]));
    setCustomerDetails(prev=>({...prev,configuredCategories:[...prev.configuredCategories.filter(el=>el!==categoryId)]}));
  }

  const [showEditModal, setShowEditModal] = useState(false);

  const optionsRef = useRef(null);
  const handleOutsideClick = (e) => {
    if(optionsRef.current && !optionsRef.current.contains(e.target) && (e.target.id!='dropdown-arrow' && e.target.id!='search-category-input' && e.target.id!='selected-categories-container')){
      setShowOptions(false);
      clearSearchFilter();
    }
  }

  const clearSearchFilter = () => {
    setSearchCategoriesInput('');
    setCategoryOptionsArray(categoriesOptions);
  }

  const handleChange = (e) => {
    const {name,value} = e.target;
    setCustomerDetails(prev=>({
      ...prev,
      [name]:value
    }));
  }

  const validateData = () => {
    const {name,configuredCategories} = customerDetails;
    let error={};
      // const error={
      //   name: !name.trim()?'Name cannot be empty':'',
      //   configuredCategories: configuredCategories.length==0?'At least 1 category is reqiured.':''
      // };

      if(!name.trim()){
        error['name'] = 'Name cannot be empty'
      }
      if(name.trim() && name.length>100){
        error={
          name: 'Number of characters in customer name should not be more than 100.'
        }
      }
      const regexAlphabets = /[a-zA-Z ]/;
      if(name.trim() && !regexAlphabets.test(name)){
        error={
          name: 'Only Numbers are not allowed in customer name.'
        }
      }
      const regex = /[^a-zA-Z0-9 ]/g;
      if(name.trim() && regex.test(name)){
        error={
          name: 'Special Characters are not allowed in customer name.'
        }
      }

      if(configuredCategories.length==0){
        error['configuredCategories'] = 'At least 1 category is reqiured.'
      }
      setValidationErrors(error);
      if(Object.values(error).find(el=>el!='')){
        return false;
      }else{
        return true;
      }
  }

  const saveDetails = async() => {
    const res = await validateData();
    if(!res){
      return
    }
    setDisableSave(true);
    const addParams = {
      name:customerDetails.name.trim(),
      key:customerDetails.key,
      status:customerDetails.status,
      configuredCategories:customerDetails.configuredCategories
    }
    if(modalTitle=='Edit'){
      const editParams = {
        id:customerDetails.id,
        name:customerDetails.name.trim(),
        status:customerDetails.status,
      }
      updateCustomerData(editParams).then(res=>{
        if(res && res.status=='logout'){
          localStorage.removeItem('user');
          navigate('/')
        }
        if(res && res.status !='logout'){
          toast(res.status,res.message);
          getCustomers();
          closeModal();
        }
      }).catch(error=>{
        console.log(error)
      })
    }else{
      addCustomerData(addParams).then(res=>{
        if(res && res.status == 'logout'){
          localStorage.removeItem('user');
          navigate('/')
          closeModal();
        }
        if(res && res.status != 'logout'){
          toast(res.status,res.message);
          getCustomers();
          closeModal();
        }
      }).catch(error=>{
        console.log(error);
      })
    }
  }

  const getCustomers = ()=>{
    const params = {
      page: pageInfo.page,
      size: pageInfo.perPageSize,
      sortColumn: pageInfo.sortColumn,
      sortType: pageInfo.sortType
    }
    getAllCustomers(params);
  }

  return (
    <div className="d-flex flex-column align-items-start pt-3" >
      <label className='keywords-input-label' htmlFor='name'>Customer Name:<span className={`validation-error`}>{validationErrors.name}</span></label>
      <input type="text" name="name" id='name' className='mb-3 form-control' placeholder='Customer Name' value={customerDetails.name} onChange={(e)=>{handleChange(e)}} />
      <label className='keywords-input-label' htmlFor='key'>Key:<span className={`validation-error`}></span></label>
      <input type="text" name="key" id='key' className='mb-3 form-control' disabled placeholder='Key' value={customerDetails.key} onChange={(e)=>{handleChange(e)}} />
      <label className='keywords-input-label' htmlFor=''>Status:<span className={`validation-error`}></span></label>
      <select className='mb-3 form-control select-control' name='status' value={customerDetails.status}  onChange={(e)=>{handleChange(e)}}>
        <option value='Enable'>Enable</option>
        <option value='Disable'>Disable</option>
      </select>
      <label className='keywords-input-label' htmlFor=''>Categories:<span className={`validation-error`}>{validationErrors.configuredCategories}</span></label>
      <div className={`categories-dropdown-container mb-3 w-100 ${modalTitle=='Edit'?'edit-categories-dropdown':''}`} ref={categoriesSelectRef}>
        <div className='input-field'>
          <div className='search-category-container'>
            <input type='text' className={`search-category-name`} placeholder='Select Categrories' id='search-category-input' disabled={modalTitle=='Edit'} value={searchCategoriesInput} onFocus={()=>{setShowOptions(true)}} onChange={(e) => { handleSearchCategory(e.target.value);setSearchCategoriesInput(e.target.value) }}/>
            <i className="fa-solid fa-chevron-down cursor-pointer" id='dropdown-arrow' onClick={() => { handleDropdown();clearSearchFilter() }}></i>
          </div>
          <div className='selected-categories-container' id='selected-categories-container'>
            {selectedCategories && selectedCategories.map(category => {
                return <span key={category.id} className='selected-category py-1'>{category.name} <i className={`fa-solid fa-xmark px-1 small`} onClick={() => { modalTitle=='Add' && removeSelectedCategory(category.id) }}></i></span>
            })}
          </div>
        </div>
        <div className={`options-container options-position-absolute dropdown-options ${ showOptions ? '' : 'd-none'} ${modalTitle=='Edit'?'d-none':''}` } ref={optionsRef}>
          {categoryOptionsArray && categoryOptionsArray.length>0?categoryOptionsArray.map(category => { return <li className={`category-option ${selectedCategories && selectedCategories.find(el => el.id == category.id) ? 'selected' : ''}`} onClick={() => { handleCategoryOptionSelect(category) }}>{category.name}</li>}):
            <div className={``}>No Options Available</div>
          }
        </div>
      </div>
      <div>
      <button className='btn btn-primary ingest-button mt-2 w-auto mx-2 px-3' disabled={disableSave} onClick={()=>{saveDetails()}}>Save</button>
      <button className='btn btn-dark ingest-button mt-2 w-auto' onClick={()=>{closeModal()}}>Cancel</button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    categoriesOptions : state.categories.categoriesOptions,
    pageInfo: state.customers.pageInfo
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addCustomerData,
    updateCustomerData,
    getAllCustomers
  },dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)( AddEdit)